<template>
  <div class="PopularScienceDemo">
     <div class="left">
       <div class="left-title">
         <p :class="titleIdx==0?'xzt':''" @click="teb(0)">眼病介绍</p>
         <p :class="titleIdx==1?'xzt':''" @click="teb(1)">处置方案</p>
         <p :class="titleIdx==2?'xzt':''" @click="teb(2)">产品功能</p>
       </div>
       <div class="left-nr">
         <h3>推荐功能</h3>
         <div v-if="titleIdx==0">
           <p @click="jtIdx=0" :class="jtIdx==0?'xzp':''">白内障</p>
           <p @click="jtIdx=1" :class="jtIdx==1?'xzp':''">青光眼</p>
           <p @click="jtIdx=2" :class="jtIdx==2?'xzp':''">眼底病</p>
           <p @click="jtIdx=3" :class="jtIdx==3?'xzp':''">角膜病</p>
           <p @click="jtIdx=4" :class="jtIdx==4?'xzp':''">眼表疾病</p>
           <p @click="jtIdx=5" :class="jtIdx==5?'xzp':''">眼外伤</p>
         </div>

         <div v-if="titleIdx==1">
           <p @click="jtIdx=0" :class="jtIdx==0?'xzp':''">屈光手术</p>
           <p @click="jtIdx=1" :class="jtIdx==1?'xzp':''">角膜接触镜</p>
           <p @click="jtIdx=2" :class="jtIdx==2?'xzp':''">框架镜</p>
           <p @click="jtIdx=3" :class="jtIdx==3?'xzp':''">视觉训练</p>
           <p @click="jtIdx=4" :class="jtIdx==4?'xzp':''">眼健康预防</p>
           <p @click="jtIdx=5" :class="jtIdx==5?'xzp':''">眼部结构模型</p>
         </div>

         <div v-if="titleIdx==2">
           <p @click="jtIdx=0" :class="jtIdx==0?'xzp':''">折射率</p>
           <p @click="jtIdx=1" :class="jtIdx==1?'xzp':''">光学设计</p>
           <p @click="jtIdx=2" :class="jtIdx==2?'xzp':''">应用场景</p>
           <p @click="jtIdx=3" :class="jtIdx==3?'xzp':''">膜层功能</p>
<!--           <p @click="jtIdx=4" :class="jtIdx==4?'xzp':''">染色</p>-->
         </div>

       </div>
     </div>
    <div class="right">
      <div class="boxs" v-for="item in list[titleIdx][jtIdx]" @click="bf(item)">
        <img :src="item.img" alt="">
        <p>{{item.title}}</p>
      </div>
    </div>


<!--    <el-dialog-->
<!--        title=""-->
<!--        class="sp"-->
<!--        :visible.sync="showsp"-->
<!--        width="80%"-->
<!--        :destroy-on-close="true"-->
<!--        >-->
<!--    </el-dialog>-->
    <div class="mgs" v-if="showsp">
      <i class="el-icon-error" @click="showsp=false"></i>
      <video style="width: 85%" controls autoplay="autoplay">
        <source :src="vsrc" type="video/mp4">
        <source :src="vsrc" type="video/ogg">
        您的浏览器不支持Video标签。
      </video>
    </div>
  </div>
</template>


<script>
import pf from "../../publicFn/baseFn"
export default {
  name: "PopularScienceDemo",
  data() {
    return {
      titleIdx: 0,
      jtIdx: 0,
      list: [[], [], []],
      showsp: false,
      vsrc: '',
      proIdlist: [],
      TimeMap: new Map()
    }
  },
  watch: {
    jtIdx: function (n, o) {
      let str = this.titleIdx.toString() + "-" + this.jtIdx.toString()
      this.setTime(str)
    }
  },
  created() {

    this.TimeMap.set('2-0', 'display_material')
    this.TimeMap.set('2-1', 'display_design')
    this.TimeMap.set('2-2', 'display_scene')
    this.TimeMap.set('2-3', 'display_film')

    this.TimeMap.set('1-0', 'display_surgery')
    this.TimeMap.set('1-1', 'display_contact')
    this.TimeMap.set('1-2', 'display_frameGlasses')
    this.TimeMap.set('1-3', 'display_eyesTraining')
    this.TimeMap.set('1-4', 'display_healthManagement')

    let arr = [[], [], []]
    arr[0][0] = [
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/bnz1.png',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpVedio/gaodujinshi-baineizhang.mp4',
        title: '白内障1'
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/bnz2.png',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/EyeDisease/1/12.mp4',
        title: '白内障2'
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/bnz3.png',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/EyeDisease/1/13.mp4',
        title: '调节性人工晶体'
      }
    ]
    arr[0][1] = [
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/qgy1.png',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/EyeDisease/2/21.mp4',
        title: '青光眼1'
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/qgy2.png',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/EyeDisease/2/22.mp4',
        title: '青光眼2'
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/qgy3.png',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/EyeDisease/2/23.mp4',
        title: '闭角型青光眼'
      }
    ]
    arr[0][2] = [
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/ydb1.png',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/EyeDisease/3/31.mp4',
        title: '早产儿视网膜病变'
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/ydb2.png',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/EyeDisease/3/32.mp4',
        title: '糖尿病视网膜病变1'
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/ydb3.png',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/EyeDisease/3/33.mp4',
        title: '糖尿病视网膜病变2'
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/ydb4.png',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/EyeDisease/3/34.mp4',
        title: '非增殖性糖尿病视网膜病变(NPDR)'
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/ydb5.png',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/EyeDisease/3/35.mp4',
        title: '增殖性糖尿病视网膜病变'
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/ydb6.png',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/EyeDisease/3/36.mp4',
        title: '干性老年性黄斑变性（AMD）'
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/ydb7.png',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/EyeDisease/3/37.mp4',
        title: '视网膜撕裂或剥离'
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/ydb8.png',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/EyeDisease/3/38.mp4',
        title: '黄斑变性'
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/bltcx.png',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/EyeDisease/3/39.mp4',
        title: '玻璃体出血'
      }
    ]
    arr[0][3] = [
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/jmb1.png',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/EyeDisease/4/41.mp4',
        title: '角膜溃疡'
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/jmb2.png',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/EyeDisease/4/42.mp4',
        title: '角膜擦伤'
      }
    ]
    arr[0][4] = [
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/ybjb1.png',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/EyeDisease/5/51.mp4',
        title: '结膜炎'
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/ybjb2.png',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/EyeDisease/5/52.mp4',
        title: '眼睑炎'
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/ybjb3.png',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/EyeDisease/5/53.mp4',
        title: '虹膜炎'
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/ybjb4.png',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/EyeDisease/5/54.mp4',
        title: '结膜下出血'
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/ybjb5.png',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/EyeDisease/5/55.mp4',
        title: '眼内异物'
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/ybjb6.png',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/EyeDisease/5/56.mp4',
        title: '眶周峰窝组织炎'
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/ybjb7.png',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/EyeDisease/5/57.mp4',
        title: '翼状胬肉'
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/ybjb8.png',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/EyeDisease/5/58.mp4',
        title: '眼睑内翻和外翻'
      }
    ]
    arr[0][5] = [
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/yws.jpg',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/EyeDisease/6/61.mp4',
        title: '眶周挫伤'
      }
    ]


    arr[1][0] = [
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/qgss1.jpg',
        vsrc: pf.getUrl('CLVideo_qfm'),
        title: '全飞秒'
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/qgss2.jpg',
        vsrc: pf.getUrl('CLVideo_zfz'),
        title: '准分子激光'
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/qgss3.jpg',
        vsrc: pf.getUrl('CLVideo_rgjt'),
        title: '人工晶体'
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/qgss4.jpeg',
        vsrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpVedio/ICLJTZR.mp4',
        title: 'ICL晶体植入'
      }
    ]
    arr[1][1] = [
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/jmjcj1.jpg',
        vsrc: pf.getUrl('CLVideo_OKLens'),
        title: 'Ortho-K镜'
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/jmjcj2.jpg',
        vsrc: pf.getUrl('CLVideo_RGP'),
        title: 'RGP'
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/jmjcj3.jpg',
        vsrc: pf.getUrl("CLVideo_RGP"),
        title: 'SCL'
      }
    ]
    arr[1][2] = [
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/kjj.jpg',
        vsrc: '',
        title: '非接触式屈光矫正',
        proId: 101
      }
    ]
    arr[1][3] = [
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/sjxl1.jpg',
        vsrc: '',
        title: '调节训练',
        proId: 102
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/sjxl2.jpg',
        vsrc: '',
        title: '脱抑制训练',
        proId: 103
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/sjxl3.jpg',
        vsrc: '',
        title: '融像训练',
        proId: 104
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/sjxl4.jpg',
        vsrc: '',
        title: '动眼功能训练',
        proId: 105
      },
    ]
    arr[1][4] = [
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/yjkyf1.jpg',
        vsrc: '',
        title: '用眼习惯',
        proId: 106
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/yjkyf2.jpg',
        vsrc: ' ',
        title: '用眼环境',
        proId: 107
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/yjkyf3.jpg',
        vsrc: '',
        title: '生长发育',
        proId: 108
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/yjkyf4.jpeg',
        vsrc: '',
        title: '营养',
        proId: 109
      }

    ]
    arr[1][5] = [
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/ybjgmx.jpeg',
        vsrc: '',
        title: '眼部结构'
      }
    ]


    arr[2][0] = [
      // {
      //   img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/zsl1.jpg',
      //   vsrc: '',
      //   title: '折射率1',
      //   proId: 1
      // },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/zsl2.jpg',
        vsrc: '',
        title: '阿贝数',
        proId: 2
      }
    ]
    arr[2][1] = [
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/gxsj1.jpg',
        vsrc: '',
        title: '单光',
        proId: 3
      },
      // {
      //   img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/gxsj2.jpg',
      //   vsrc: '',
      //   title: '渐进',
      //   proId: 4
      // },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/gxsj3.jpg',
        vsrc: '',
        title: '抗疲劳',
        proId: 5
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/gxsj4.jpg',
        vsrc: '',
        title: '儿童眼健康',
        proId: 6
      }
    ]
    arr[2][2] = [
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/yycj1.jpg',
        vsrc: '',
        title: '驾驶',
        proId: 100
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/yycj2.jpg',
        vsrc: '',
        title: '偏光',
        proId: 110
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/yycj3.jpg',
        vsrc: '',
        title: '防蓝光',
        proId: 9
      },
      // {
      //   img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/yycj4.jpg',
      //   vsrc: '',
      //   title: '快变色',
      //   proId: 10
      // }
    ]
    arr[2][3] = [
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/mcgn1.jpg',
        vsrc: '',
        title: '防尘防静电',
        proId: 11

      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/mcgn2.jpg',
        vsrc: '',
        title: '防水',
        proId: 12
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/mcgn3.jpg',
        vsrc: '',
        title: '防雾',
        proId: 13
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/mcgn4.jpg',
        vsrc: '',
        title: '抗磨损',
        proId: 14
      },
      {
        img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/mcgn5.jpg',
        vsrc: '',
        title: '减反射',
        proId: 15
      }
    ]
    arr[2][4] = [
      // {
      //   img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/rs1.jpg',
      //   vsrc: '',
      //   title: '全染色',
      //   proId: 16
      // },
      // {
      //   img: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vpjb/rs2.jpg',
      //   vsrc: ' ',
      //   title: '渐变色',
      //   proId: 17
      // }
    ]

    let proIdlist = ['/PopularScienceHome/totalDrive','/PopularScienceHome/frameMirror','/PopularScienceHome/visualTraining','/PopularScienceHome/eyeHabit','/PopularScienceHome/eyeEnvironment','/PopularScienceHome/growUp','/PopularScienceHome/pabulum','/PopularScienceHome/polarized'];
    this.proIdlist = proIdlist;

    this.list = arr
  },
  methods: {
    teb(idx) {
      this.titleIdx = idx
      this.jtIdx = 0
    },
    bf(e) {
      this.vsrc = ''
      if(e.proId) {
        if(e.proId >= 100) {
          let tag = 100;
          if(e.proId >= 106) {
            tag = 103;
          }
          if(e.proId > 101 &&  e.proId < 106) {
            this.$router.push({path: '/PopularScienceHome/visualTraining', query: {
                proId: e.proId
              }})
          }
          else {
            this.$router.push({path: this.proIdlist[e.proId - tag]})
          }

        }
        else {
          this.$router.push({path: '/PopularScienceHome/proFunction', query: {
              proId: e.proId
            }});
        }


      }
      else if (e.vsrc) {
        //console.log(e.vsrc)
        this.vsrc = e.vsrc
        this.showsp = true
      }
    },
    jump(e) {},
    setTime(str) {

      // console.log(      this.TimeMap.get(str))
      // console.log(this.TimeMap.keys())
      let arr =  Array.from(this.TimeMap.keys())
      for (let i = 0; i <arr.length; i++) {
        if (str == arr[i]) {
          this.cshTime(this.TimeMap.get(str))
        }else {
          if (this.$store.state.Time.timeObj[this.TimeMap.get(arr[i])].ENTER != 0) {
            this.jsTime(this.TimeMap.get(arr[i]))
          }
        }
      }
    },

    cshTime(keys) {
      this.$store.commit('Time/upTimeObj', {
        keys: keys,
        val: {
          ENTER: (new Date()).valueOf(),
          EXIT: this.$store.state.Time.timeObj[keys].EXIT
        }
      })
    },
    jsTime(keys,types=0) {
      if (types) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: 0,
            EXIT: 0
          }
        })
        return
      }
      if (this.$store.state.Time.timeObj[keys].ENTER !== 0) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: (new Date()).valueOf(),
            EXIT: ((new Date()).valueOf() - this.$store.state.Time.timeObj[keys].ENTER)/1000 + Number(this.$store.state.Time.timeObj[keys].EXIT)
          }
        })
      }
    }
  },
  beforeDestroy() {
    let arr = Array.from(this.TimeMap.keys())
    for(let i = 0; i < arr.length; i++) {
       if (this.$store.state.Time.timeObj[this.TimeMap.get(arr[i])].ENTER != 0) {
         let times = ((new Date()).valueOf() - this.$store.state.Time.timeObj[this.TimeMap.get(arr[i])].ENTER)/1000 + Number(this.$store.state.Time.timeObj[this.TimeMap.get(arr[i])].EXIT)
         this._api.publicApi.addTime(this.TimeMap.get(arr[i]), times)
         this.jsTime(this.TimeMap.get(arr[i]), 1)
       }
    }
  }
}
</script>

<style scoped lang="scss">
  .PopularScienceDemo {
    display: flex;
    justify-content: start;
  }
  .left {
    width: 25vw;
    padding-top: 30px;

    .left-title {
      display: flex;
      align-content: center;
      justify-content: center;
      width: 90%;
      margin: 0 auto;
      background: rgba(206,206,206,0.7);
      border-radius: 7px;
      padding: 4px 0;
      p {
        width: 33%;
        padding: 5px 0;
        border-radius: 7px;
        cursor: pointer;
        &:hover {background: #FFFFFF}
      }
      .xzt {background: #FFFFFF}
    }

    .left-nr {
      padding-top: 2vh;
      width: 90%;
      margin: 0 auto;
      p {
          padding: 11px 0;
          border-bottom: 1px solid #d4d2d2;
          &:hover {color: #b4b3b3}
        cursor: pointer;
      }
      .xzp {color: #b4b3b3}
    }
  }

  .right {
    width: 75vw;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    height: 85vh;
    overflow-y: auto;
    .boxs {
      width: 200px;
      height: auto;
      cursor: pointer;
      margin: 30px;
      img {
        width: 170px;
      }
    }
  }
  .sp {
    display: flex;
    align-content: center;
    justify-content: center;
  }
  ::v-deep .sp .el-dialog {
    margin: 5vh !important;
    height: 90vh;
    //margin-top: 10vh;
  }
  .mgs {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
    z-index: 99999999;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 35px;
      color: #ff0101;
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }
  }
</style>
